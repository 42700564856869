import { RouteConfig } from 'vue-router'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'

const routeConfigs: Array<RouteConfig> = [
  {
    path: '/auth',
    component: () => import('/~/views/auth/auth-view.vue'),
    beforeEnter(_, __, next) {
      const { user } = useUser()
      const { isOauthEnabled } = useProvider()

      if (user.value.authorized) {
        // redirect to home if user is already logged in
        next({ name: 'home' })
      } else if (isOauthEnabled.value) {
        // redirect to oauth if oauth is enabled
        window.location.replace('/login')
      } else {
        next()
      }
    },
    meta: {
      guestAccess: true,
    },
    children: [
      {
        path: 'login',
        name: 'auth-login',
        component: () => import('/~/views/auth/login/auth-login.vue'),
        meta: {
          title: 'Login',
        },
      },
      {
        path: 'request-code',
        name: 'request-code',
        component: () => import('/~/views/auth/2fa/request-code.vue'),
        meta: {
          title: 'Request Code',
        },
      },
      {
        path: 'verify-account',
        name: 'verify-account',
        component: () => import('/~/views/auth/2fa/verify-account.vue'),
        meta: {
          title: 'Verify your account',
        },
      },
      {
        path: 'account-locked',
        name: 'auth-account-locked',
        component: () => import('/~/views/auth/error/account-locked.vue'),
        meta: {
          title: 'Account Locked',
        },
      },
      {
        path: 'account-temporarily-locked',
        name: 'auth-account-temporarily-locked',
        component: () =>
          import('/~/views/auth/error/account-temporarily-locked.vue'),
        meta: {
          title: 'Account Temporarily Locked',
        },
      },
      {
        path: 'register',
        name: 'auth-register',
        component: () => import('/~/views/auth/register/auth-register.vue'),
        beforeEnter(to, from, next) {
          const { isRegistrationFormEnabled, isClaimsEnabled } = useProvider()
          const { hasUserClaim } = useUser()

          if (!isRegistrationFormEnabled.value) {
            return next({ name: 'home' })
          }

          if (!isClaimsEnabled.value) {
            return next()
          }

          return hasUserClaim.value ? next() : next({ name: 'home' })
        },
        props: (route) => ({
          query: route.query,
        }),
        meta: {
          title: 'Registration',
        },
      },
      {
        path: 'register/failed',
        name: 'auth-register-failed',
        component: () =>
          import('/~/views/auth/register/auth-register-failed.vue'),
        meta: {
          title: 'Registration Failed',
        },
      },
      {
        path: 'register/success',
        name: 'auth-register-success',
        component: () =>
          import('/~/views/auth/register/auth-register-success.vue'),
        meta: {
          title: 'Registration Success',
        },
      },
      {
        path: 'create-password/:token',
        name: 'create-password',
        component: () => import('/~/views/auth/password/create-password.vue'),
        meta: {
          title: 'Create Password',
        },
      },
      {
        path: 'password/:id?',
        name: 'auth-password',
        component: () => import('/~/views/auth/password/auth-password.vue'),
        meta: {
          title: 'Password Reset',
        },
      },
      {
        path: 'password/:id/:token/',
        alias: '/password/:id/:token/',
        name: 'auth-reset',
        component: () => import('/~/views/auth/reset/auth-reset.vue'),
        meta: {
          title: 'Password Reset',
        },
      },
    ],
  },
  {
    path: '/sign-out',
    name: 'sign-out',
    props: true,
    component: () => import('/~/views/auth/sign-out/sign-out.vue'),
    meta: {
      page: 'sign-out',
      guestAccess: true,
    },
  },
]

export default routeConfigs
